<template>
  <b-row>

    <!--  Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required"
      >
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="packageData.name"
            :state="getValidationState(validationContext)"
            placeholder="Package Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
     <!--  Name Ar -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required"
      >
        <b-form-group
          label="Name Arabic (اسم الحزمة)"
          label-for="nameArabic"
        >
          <b-form-input
            id="nameAr"
            v-model="packageData.nameAr"
            :state="getValidationState(validationContext)"
            placeholder="اسم الحزمة"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- branchesLimit -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="branchesLimit"
        rules="required"
      >
        <b-form-group
          label="Branches Limit"
          label-for="branchesLimit"
        >
          <b-form-input
            id="branchesLimit"
            v-model="packageData.branchesLimit"
            :state="getValidationState(validationContext)"
            placeholder="Branches Limit"
            type="number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- cashiersPerBranchLimit -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="cashiersPerBranchLimit"
        rules="required"
      >
        <b-form-group
          label="Cashier per Limit Branch"
          label-for="cashiersPerBranchLimit"
        >
          <b-form-input
            id="cashiersPerBranchLimit"
            v-model="packageData.cashiersPerBranchLimit"
            :state="getValidationState(validationContext)"
            placeholder="Cashier per Limit Branch"
            type="number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col
      sm="12"
    >
      <b-form-group
        label="With Hardware"
        label-for="withHardware"
      >
        <b-form-checkbox
          id="withHardware"
          v-model="packageData.withHardware"
          checked="true"
          name="withHardware"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

     <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="packageData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
     <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="descriptionArabic"
        rules="required"
      >
        <b-form-group
          label="Description Arabic (الوصف عربي)"
          label-for="descriptionArabic"
        >
          <b-form-textarea
            id="descriptionAr"
            v-model="packageData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="الوصف عربي"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback,BFormCheckbox, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    packageData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      //  Form
      getValidationState,
    }
  },
}
</script>
