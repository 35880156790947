<template>
  <b-row>
    <!--  Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="title"
        rules="required"
      >
        <b-form-group label="Title" label-for="title">
          <b-form-input
            id="Title"
            v-model="subscription.title"
            :state="getValidationState(validationContext)"
            placeholder="Subscription Title"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!--  Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="title Arabic (عنوان الاشتراك)"
        rules="required"
      >
        <b-form-group label="Title" label-for="title">
          <b-form-input
            id="TitleAr"
            v-model="subscription.titleAr"
            :state="getValidationState(validationContext)"
            placeholder="عنوان الاشتراك"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!--             subtitle -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="subTitle"
        rules="required"
      >
        <b-form-group label="Sub Title" label-for="subTitle">
          <b-form-input
            id="subTitle"
            v-model="subscription.subTitle"
            :state="getValidationState(validationContext)"
            placeholder="Sub Title"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

     <!--             subtitle -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="subTitle Arabic"
        rules="required"
      >
        <b-form-group label="Sub Title (العنوان الفرعي)" label-for="subTitleAr">
          <b-form-input
            id="subTitleAr"
            v-model="subscription.subTitleAr"
            :state="getValidationState(validationContext)"
            placeholder="العنوان الفرعي"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- fees -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="fees"
        rules="required"
      >
        <b-form-group label="Price (fees)" label-for="fees">
          <b-form-input
            id="fees"
            v-model="subscription.price"
            :state="getValidationState(validationContext)"
            placeholder="Price (fees)"
            type="number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- tenure -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="tenure"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="tenure"
          label-for="tenure"
          :state="getValidationState(validationContext)"
        >
          <v-select v-model="subscription.tenure"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="[ {
          code: 'Y', label: 'Yearly' }, { code: 'M', label: 'Monthly' }, ]"
          class="w-100" input-id="tenure" />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,
    vSelect,

    //  Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getValidationState } = formValidation();

    return {
      //  Form
      getValidationState,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
