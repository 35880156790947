<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(create)">
        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <package-form :packageData="packageData" />
      </b-form>
    </validation-observer>

    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(addSubscription)">
        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Add Subscription</span>
            </b-button>
          </b-col>
        </b-row>

        <subscription-form :subscription="subscription" />
      </b-form>
    </validation-observer>
    <subscription-list :subscriptions="subscriptionList" />
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BForm, BButton } from "bootstrap-vue";
import { onUnmounted, ref } from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";
import store from "@/store";
import router from "@/router";
import PackageForm from "../package-form/PackageForm.vue";
import usePackageForm from "../package-form/usePackageForm";
import SubscriptionForm from "../package-form/SubscriptionForm.vue";
import subscriptionPackageStore from "../subscriptionPackageStore";
import SubscriptionList from "../subscription-list/SubscriptionList.vue";
import clearForm from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    PackageForm,
    SubscriptionForm,
    SubscriptionList,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    };
  },

  setup() {
    const PACKAGE_APP_STORE_MODULE_NAME = "app-package";

    // Register module
    if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME))
      store.registerModule(
        PACKAGE_APP_STORE_MODULE_NAME,
        subscriptionPackageStore
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME))
        store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME);
    });

    let { packageData } = usePackageForm();
    let subscription = ref({});
    let subscriptionList = [];
    let newarr = [];

    async function createPackage() {
      try {
        let newpkg = packageData.value;
        newpkg.subscriptions = subscriptionList;
        const response = await store.dispatch(
          "app-package/createPackage",
          newpkg
        );

        if (response.data.status) {
          router.push({ name: "general-settings-subscriptionPackage-list" });
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function create() {
      try {
        //packageData.subscription = subscriptionList;
        createPackage();
      } catch (error) {
        console.error(error);
      }
    }
    function addSubscription() {
      try {
        debugger;
        // subscription.value.tenure = subscription.value.tenure.code;
        let newobj = {
          title: subscription.value.title,
          subTitle: subscription.value.subTitle,
          titleAr: subscription.value.titleAr,
          subTitleAr: subscription.value.subTitleAr,
          price: subscription.value.price,
          tenure: subscription.value.tenure.code,
        };
        newarr.push(newobj);
        subscriptionList.push(newobj);
        document.getElementsByTagName("tbody")[0].innerHTML = "";
        let html = "";
        newarr.forEach((element) => {
          html +=
            '<tr role="row" class=""><td aria-colindex="1" role="cell" class=""> ' +
            element.title +
            "(" +
            element.titleAr +
            ")" +
            ' </td><td aria-colindex="2" role="cell" class=""> ' +
            element.subTitle +
            "(" +
            element.subTitleAr +
            ")" +
            ' </td><td aria-colindex="3" role="cell" class=""><span>' +
            element.price +
            '</span></td><td aria-colindex="4" role="cell" class=""> ' +
            element.tenure +
            " </td></tr>";
        });
        document.getElementsByTagName("tbody")[0].innerHTML += html;
        clearForm();
      } catch (error) {
        console.error(error);
      }
    }

    return {
      //  Data
      packageData,
      subscription,
      subscriptionList,

      //  Action
      create,
      addSubscription,
    };
  },
};
</script>
