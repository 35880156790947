<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="border-light">
      <b-table
        class="position-relative"
        :items="subscriptions"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: title -->
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <!-- Column: Sub subTitle -->
        <template #cell(subTitle)="data">
          {{ data.item.subTitle }}
        </template>

        <!-- Column: price -->
        <template #cell(price)="data">
          <span>{{ data.item.price.fees + data.item.price.vat }}</span> 
        </template>

        <!-- Column: tenure -->
        <template #cell(tenure)="data">
          {{ data.item.tenure === 'Y' ? 'Yearly' : 'Monthly' }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useSubscriptionList from "./useSubscriptionList";
import store from "@/store";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { tableColumns } = useSubscriptionList();

    return {
      tableColumns,
    };
  },
  
};
</script>
