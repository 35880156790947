import { ref, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import subscriptionPackageStore from '../subscriptionPackageStore'

export default function usePackageForm() {
  const PACKAGE_APP_STORE_MODULE_NAME = 'app-package'

  // Register module
  if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(PACKAGE_APP_STORE_MODULE_NAME, subscriptionPackageStore)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME)
  })

  const packageData = ref({
    withHardware: false,
  })

  const fetchRetailer = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchRetailer', params)
      // eslint-disable-next-line prefer-destructuring
      packageData.value = response.data.data[0]
      return response
    } catch (error) {
      packageData.value = undefined
      return error
    }
  }

  const packages = computed(() => store.state['app-package'].packages)

  const packageOptions = computed(() => store.getters['app-package/packageOptions'])

  const packageSubscriptionOptions = computed(() => store.getters['app-package/packageSubscriptionOptions'])

  const fetchPackageSubscriptions = packageId => {
    if (packages.value && packages.value.length === 0) {
      return
    }

    const packageSubscriptions = packages.value.find(obj => packageId === obj.packageId)

    if (!packageSubscriptions) {
      return
    }

    if (packageSubscriptions && packageSubscriptions.subscriptions && packageSubscriptions.subscriptions.length === 0) {
      return
    }

    store.commit('app-package/SET_PACKAGE_SUBSCRIPTIONS_OPTIONS', packageSubscriptions.subscriptions)
  }

  const fetchPackages = async params => {
    try {
      const response = await store.dispatch('app-package/fetchPackages', params)
      return response
    } catch (error) {
      return error
    }
  }

  return {
    packageData,

    packageOptions,
    packageSubscriptionOptions,

    fetchRetailer,
    fetchPackages,
    fetchPackageSubscriptions,
  }
}
