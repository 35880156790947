import { computed } from '@vue/composition-api'

import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useSubscriptionList() {
  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'subTitle', sortable: true, label: 'Sub Title' },
    { key: 'price', sortable: true },
    { key: 'tenure', sortable: true },
  ]

  // Call System Codes
  useSystemCodes()
  
  return {
    tableColumns,
  }
}
