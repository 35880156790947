import { fetchPackagesAPI, createPackageAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
    packages: [],
    packageSubscriptions: [],
  },
  getters: {
    packageOptions(state) {
      if (state.packages.length <= 0) {
        return []
      }
      return state.packages.map(obj => ({
        label: obj.name,
        value: obj.packageId,
      }))
    },
    packageSubscriptionOptions(state) {
      if (state.packageSubscriptions.length <= 0) {
        return []
      }
      return state.packageSubscriptions.map(obj => ({
        label: obj.title,
        value: obj.subscriptionId,
      }))
    },
  },
  mutations: {
    SET_PACKAGE_OPTIONS(state, data) {
      state.packages = data
    },
    SET_PACKAGE_SUBSCRIPTIONS_OPTIONS(state, data) {
      state.packageSubscriptions = data
    },
  },
  actions: {
    // *===============================================---*
    // *--------- Packages ---- ---------------------------------------*
    // *===============================================---*
    async fetchPackages(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchPackagesAPI, {
          params: queryParams,
        })
        ctx.commit('SET_PACKAGE_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async createPackage(ctx, params) {
      try {
        return await this.$axios.post(createPackageAPI, params)
      } catch (error) {
        return error
      }
    },
  },
}
